.eventPage{
    background-color: rgb(255, 255, 255);
    max-width: 100%;
    padding-top: 5%;
    height: 600px;
    align-items: center;
}
.blackHeading{
    color: var(--blue);
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    margin-top: 40px;
}
.container{
    background-color: rgba(255, 255, 255, 0.1);
    max-width: 100%;
    /* height: 80%; */
    height: fit-content;
    display:flex;
    flex-direction: row;
    /* margin-top: 20px; */
    margin: 0%;
    padding-top: 0px;
    padding: 0 80px 0 80px;
    justify-content: center;
    align-items: center;
}
.eventInfo{
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
    width: 55%;
    height: 300px;
    align-items: center;
}
.title{
    margin-top: 45px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2.5rem;
    padding:0;
    text-decoration: none;
    margin-bottom: 2%;
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }

}
.eventText{
    width: 80%;
    text-align: justify;
    margin-left: 10%;
    font-size: 20px
}
.line{
    background-color: black;
    width:1px;
    height:100%;
    margin-left: 10px;
    margin-right: 20px;
}
.media{
    width:450px;
    height:300px;
    border-radius: 2%;
}
.video{
    width:100%;
    height:100%;
}

.splide__arrow{
    color:rgb(7, 9, 9);
    background: rgba(53, 43, 43, 0) !important;
    border-color: transparent !important;
}
.splide__arrow--prev{
    left: 40px;
    top:60%;
    color: rgb(240, 248, 255);
}
.splide__arrow--next{
    right: 60px;
    top:60%;
}
.splide__pagination{
    background-color: rgba(255, 255, 255, 0);

    
}
.splide__pagination--ltr{
    background-color: rgba(255, 255, 255, 0) !important;
    position: relative;
    margin-top: 100px;
}
.splide__pagination__page.is-active{
    background: rgb(60, 103, 171) !important;
}

.slick-dots {
    bottom: 10px; /* Adjust the position */
    z-index: 1;
  }
  
  .slick-dots li {
    margin: 0 5px; 
  }
  
  .slick-active button:before {
    color: #ff5733; /* Change the color of the active dot */
  }
@media screen and (max-width:1000px){
    .container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 0;
    }
    .eventInfo{
        width: 80%;
    }
    .title{
        font-size: 1.7rem;
    }
    .line{
        display: none;
    }
    .eventText{
        font-size:12px;
    }
    .media{
        height: 200px;
        width: 90%;
    }
    .video{
        height: 100%;
        width: 100%;
    }
    .splide__pagination{
        padding: 0%;
        margin-top: 10%;
        background-color: #ffffff;
    }
    .splide__arrow{
        margin-top: -100px;

    }
}
@media screen and (max-width:1000px) and (min-width:600px){
    .eventText{
        font-size: 1.1rem;
    }
    .media{
        margin-top: 0;
        height: 220px;
        width: 360px;
    }
    .splide__pagination--ltr{
        margin-top: -100px !important;
        background-color: black;
    }
}
@media screen and (max-width:750px) {
    .eventInfo{
        height: 230px;
    }
}

