.pricingSection{
    /* min-device-width:100%; */
    margin: 0%;
    /* height: 100vh; */
    min-height: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    text-align: center;
    padding: 50px 0 50px 0;
}
.priceHeading{
    text-align: center;
    font-size: 2.4rem;
    font-family: 'Montserrat', sans-serif;
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    margin-bottom: 20px;
}

.button-34{
  background: #5E5DF0;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  height: 40px;
  width:auto;
  font-size: 1.2em;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 30px;
}


.button-34 :hover{
    box-shadow: rgb(1, 11, 22) 0px 20px 30px -10px;
}

.button-34 a{
    text-decoration: none;
}
.button-34 a:visited {
    color: inherit; 
    text-decoration: none !important; 
}
.buttonIcon{
    margin-bottom: -2%;
    height:90%;
    width:20%;
    margin-left: 10px;
    /* background-color: #5E5DF0; */
}
.mobileSection{
    display: none;
}
.enquiryForm{
    margin-top: 30px;
    background-image: url(wickedbackground.png);
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    height: 600px;
    width:1000px;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    padding: 0%;
}
.messageHeading{
    margin-top: 20px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}
.logoHolder{
    width:50%;
    padding:5%;
    height: 100%;
    background-color: #5d5df000;
    align-items: center;
    display: flex;
}
.formLogo{
    width:100%
}
.formHolder{
    width:100%
}
.details{
    backdrop-filter: blur(9px) saturate(200%);
    -webkit-backdrop-filter: blur(9px) saturate(200%);
    background-color: rgba(17, 25, 40, 0.4);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    width: 95%;
    height: 400px;
    /* align-items: center; */
    padding:30px 10% 10px 10%;
    display: flex;
    /* grid-gap: 20px; */
    /* grid-template-columns: 3fr 2fr; */
    flex-direction: column;
    grid-row-gap: 05px ;
    border: 1px solid rgba(255, 255, 255, 0.185);
    border-radius: 5px;
    margin: 0%;
}
.details input{
    font-size: 1.2em;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    padding-left: 1%;
    height: 40px;
    width:100%;
    white-space: 20px;
    padding: 20px;  
    color:white;
}
input::placeholder , textarea::placeholder, select{
    color: rgba(255, 255, 255, 0.662);
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.details textarea{
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 0px ; 
    color:white;
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(7px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    height: 100px;
    font-size: 1.1em; 
    padding: 10px; 
    border-radius: 12px;
    width: 100%;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif

}
.details select {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.662);
    background-color: #ec17177d;
    border: none;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    padding-left: 15px;
    height: 40px;
    width: 100%;
    white-space: 20px;
  }
  select {
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    /* background-image: url('custom-arrow.png');  */
    background-position: right 10px center;
    /* background-repeat: no-repeat; */
    padding-right: 100px; 
  }
  @media screen and (min-width:600px){
    .pricingSection h1{
        text-align: left;
  }
  .button-36{
        width: auto;
  }
  }
  @media screen and (max-width:1000px){
    .pricingSection{
        height: fit-content;
        padding: 30px 0 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .priceHeading{
        font-size: 1.6em;
    }
    .blackHeading{
        font-size: 1.2em;
    }
    .pricingSection button{
        color: #FFFFFF;
        height: 40px;
        width:120px
    }
    .mobileSection{
        display: block;
    }
    .enquiryForm{
        width:90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        height:550px; 
        padding: 0%;
        margin-top: 50px;
    }
    .messageHeading{
        display: none;
    }
    .logoHolder{
        background: rgb(255, 255, 255);
        border-radius: 50%;
        height: 90px;
        width: 90px;
        margin-top: -40px;
    }
    .formLogo{
        width: 130%;
        /* height: 100%; */
        margin-left: -5px;
    }
    .formHolder{
        width: 90%;
        max-height: fit-content;
        height: 100%;
        padding-top: 20px;
    }
    .details{
        padding: 20px;
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 90%;
        font-size: 1.1em;
        grid-gap:2px;
    }
    .details input {
        height: 30px;
        width:280px;
        font-size: 16px;
    }
    .details select{
        height:40px;
        width:280px;
        padding-left: 10px;
        font-size: 16px;
        /* padding-right: 10px; */
    }
    .details textarea{
        width:280px;
        font-size: 16px;
        padding-left: 10px;
        
    }
}

@media screen and (max-width:365px) {
    .details input, .details textarea, .details select {
        width:220px
    }

}











.button-36{
    background: rgb(8,28,154);
    background: linear-gradient(103deg, rgba(8,28,154,1) 15%, rgba(195,105,225,1) 70%);
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px;
    height: 60px;
    word-break: break-word;
    border: 0;
    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    /* margin-top: 10%; */
}