.logo{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.5rem;
    /* font-size: 1.3rem; */
    height: 100%;
    padding:0;
    padding-top: 5px;
    text-decoration: none;
    z-index: 1;
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("../Pages/grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
}
.navigation-bar{
    background: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 1;
    padding: 0px 90px 0 80px;
    text-align: center;
}
.navigation-bar-white{
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 1;
    padding: 0px 80px 0 80px
}
.navigation-menu, .closed-navigation-menu{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    font-size: 20px;
    justify-content: center;
}
.navlist{
    display: flex;
    flex-direction: row;
    list-style:none;
}
.navMenuIcon{
    fill: blue
}
.navigation-link{
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("../Pages/grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-decoration: none;
    /* padding: 0 20px ; */
    padding-left: 30px;
}
.navigation-link:hover{
    color: #189AB4;
    transition: 0.5s ease;
}
.smaller-screen{
    display: none;
}
.menu-icon{
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("../Pages/grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    font-size: 1.4rem;
}

@media screen and (max-width:1135px){
    .logo{
        font-size: 1.2rem;
        padding-left: 0px;
        margin: 0%;
        margin-top: 0px;
        padding-top: 8px;
    }
    .navigation-bar{
    padding: 0px 30px 0 30px
    }
    .navigation-bar-white{
    padding: 0px 30px 0 30px
    }
    .navlist{
        display: flex;
        flex-direction: column;
    }
    .navigation-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        right: 0px;
        top:60px;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        /* background: linear-gradient(114deg, rgba(4,59,81,1) 0%, rgba(5,68,94,1) 100%);  */
        padding: 40px 0 0 10px;
        /* margin-top: -11px; */
    }
    .closed-navigation-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        right: -3000px;
        top:90px;
        align-items: flex-start;
        width: 300px;
        height: 100%;
        padding: 40px 0 0 10px;
        margin-top: -11px;
        transition: 0.8s ease-in-out;
    }
    .navigation-menu li{
        margin-bottom: 25px;
    }
    .navigation-link{
        color: var(--purple);
        font-size: 1.5rem;
        margin-bottom: 25px;
    }
    .navigation-link:hover{
        color: #189AB4;
        transition: 0.5s ease;
    }
    .smaller-screen{
        display: block;
        padding-right: 0;
    }
}
@media screen and (max-width:1135px) and (min-width:600px){
.logo
{
    padding-left: 20px;
}
.smaller-screen{
    padding-right: 30px;
}
.navigation-menu{
    width: 40%;
    background-color: rgb(255, 255, 255);
    color: black;
}
.navlist{
    display: flex;
    flex-direction: column;
    list-style:none;
}
.closed-navigation-menu{
    display:hidden;
}
}
@media screen and (max-width:360px) {
    .navigation-bar{
        padding-left: 5px;
        padding-right: 2px;
    }
    .logo{
        font-size: 16px;
    }
    
}
