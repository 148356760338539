.contactus {
  background: url(../Pages/grad.png);
  backdrop-filter: blur(25px) saturate(65%);
  -webkit-backdrop-filter: blur(25px) saturate(65%);
  background-color: rgba(17, 25, 40, 0.85);
  /* border-radius: 12px; */
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: white;
  height:fit-content;
  padding-top: 20px;
  padding-bottom: 60px;
  text-align: center;
}

.contactTitle {
  /* margin-bottom: 20px; */
  color: white;
  font-size: 1.7rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.socialMediaLinks {
  /* background: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.socialButton {
  /* From https://css.glass */
  /* background: rgba(8, 6, 154, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(8, 6, 154, 0.3); */
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);

  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.socialIcon {
  /* height: 35px; */
  /* width: 35px; */
  cursor: pointer;
}

.footerLine {
  width: 90%;
  height: 1px;
  background: white;
  text-align: center;
  margin-left: 5%;
}

.footerData {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
}

.footerUL {
  list-style: none;
  font-size: 20px;
  margin-left:-20px;
}
 .footerLi{
  margin-top: 10px;
  font-style: none;
  /* text-decoration: none; */
 }
 a{
  text-decoration: none;
  color: white;
 }
.footerLi a:visited{
  color: inherit;
  /* font-style: none; */
  text-decoration: none !important;
}
.footerLogo{
  /* height: 30px; */
  font-size:30px;
  margin-top: 20px;
}
.footerLogo .material-symbols-outlined {
  font-size: 1.2em; /* Adjust the value as needed */
}
@media screen and (max-width:600px) {
  .contactTitle {
    /* margin-bottom: 20px; */
    color: white;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  .socialButton {
    height: 40px;
    width: 40px;
  }
  .footerData{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
  }

}