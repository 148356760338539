:root {
  --purple: #BB69E1;
  --blue: #0B4BAE;
  --bgColor: rgb(8, 28, 154);
  --gradient: linear-gradient(103deg, rgba(8, 28, 154, 0.1153054971988795) 15%, rgba(195, 105, 225, 0.1012998949579832) 70%);
}


.homePage {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100vh;
  background: rgb(0, 0, 0);
  padding-left: 5%;
  padding-top: 5%;
  align-items: center;
  margin-bottom: 35px;
}


.introtext {
  width: 75%;
  margin-top: 80px;
}

.fancy {
  font-size: 3rem;
  /* opacity: 0; */
  transform: translateY(20px);
  text-transform: uppercase;
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background-image: url("logo1.jpeg");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  ;

}

.text {
  color: white;
  font-size: 2rem;
  /* opacity: 0; */
  transform: translateY(20px);
}

.contactUs {
  position: fixed;
  left: 93%;
  top: 90%;
  border: none;
  background: rgb(8, 28, 154);
  background: linear-gradient(103deg, rgba(8, 28, 154, 1) 15%, rgba(195, 105, 225, 1) 70%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
  color: white;
  padding: 15px 0 0 15px;
}

.contactUs :hover {
  transform: scale(1.2);
  padding-top: 0px;
}

.hideContact {
  display: none;
}

.optionsContainer {
  position: fixed;
  left: 93%;
  top: 82%;
  border: none;
  background: rgb(8, 28, 154);
  background: linear-gradient(103deg, rgba(8, 28, 154, 1) 15%, rgba(195, 105, 225, 1) 70%);
  width: 60px;
  height: 130px;
  border-radius: 50px;
  z-index: 1;
  color: white;
  padding-left: 12px;
  padding-top: 20px;
}
.optionsContainerLogo{
  height: 40px;
  width:40px;
  left: 20px;
  top:40px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 600px) {
  .homePage {
    text-align: left;
    display: flex;
    font-size: 30px;
    align-items: center;
    padding: 5%;
  }

  .introtext {
    font-size: 30px;
    width: 90%;
  }

  .fancy {
    font-size: 1.15em;
  }

  .text {
    font-size: 20px;
  }

  .contactUs {
    top: 85%;
    left: 80%;
    height: 50px;
    width: 50px;
    padding: 2%;
  }
  .optionsContainer {
    left: 80%;
  }
  
}

@media screen and (min-width: 600px) and (max-width: 1135px) {
  .homePage {
    padding: 5%;
    width: 100%;
    align-items: center;
  }

  .text {
    font-size: 1.2rem;
  }

  .contactUs {
    left: 85%;
    height: 50px;
    width: 50px;
    padding: 1%;
  }

  .optionsContainer {
    left: 85%;
  }
}
@media screen and (max-height:900px) {
  .optionsContainer{
      top:75%
    }
}
@media screen and (max-height:600px) {
  .contactUs {
    position: fixed;
    top: 80%
  }
  .optionsContainer {
    top: 60%;
    /* left: 60%; */
  }
}
@media screen and (max-height:400px){
  .optionsContainer{
    top:50%;
    /* background: black; */
  }
}




@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}





