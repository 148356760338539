.photobooths{
    width:100%;
    height:100%;
    background-color: rgb(255, 255, 255);
    background: rgb(8,28,154);
    background: linear-gradient(103deg, rgba(8,28,154,0.1153054971988795) 15%, rgba(195,105,225,0.1012998949579832) 70%);
    color: white;
    padding: 30px 0 80px 0;
    padding-top: 30px;

}
.heading360{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    font-weight: 300;
    margin-top: 40px;
}
.photoBoothContainer{
    width: 380px;
    height:400px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 10px 30px 10px;
    align-items: center;
    border-radius: 10px;
    background-size: cover;
}
.innerContainer{
    width: 100%;
    height: 100%;
    padding: 20px;
    color: rgb(5, 5, 5);
    backdrop-filter: blur(1px) saturate(200%);
    -webkit-backdrop-filter: blur(1px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.33);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}
.splide__slide {
    display: flex;
    justify-content: center;
    text-align: center;
}
.photoBoothHeading{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: white;
    /* @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      } */
}
.photBoothTextDiv{
    margin-top: 20px;
}
.photoBoothText{
    margin-top: 40px;
    padding-top:40px !important ;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem !important;
    color: white;
}
.ppagi{
    margin-top: 200px;
}
.included{
    text-align: center;
    padding: 0px;
    box-sizing: border-box;
    width: 100%;
    margin: 0%;
}
.includedList{
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0%;
    width: 100%;
    justify-content: center;
}
.eventContainer{
    border-radius: 12px;
    /* padding: 10px; */
    margin-top: 8%;
    margin-bottom: 10px;
    width:300px;
    height: 320px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: black;
    /* background-color: rgb(50, 45, 45); */
    /* background: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fyourcityphotobooth.com%2Fwp-content%2Fuploads%2F2021%2F08%2F360-photo-booth-product-photo-web-1.jpg&f=1&nofb=1&ipt=32598326b07bf324665d18bbd7c5dea7616ba4d2531c7ff15cb268157d9fe0b7&ipo=images"); */
    background-size: cover;
}
.inEventContainer{
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px) saturate(200%);
    -webkit-backdrop-filter: blur(4px) saturate(200%);
    background-color: rgba(17, 25, 40, 0.06);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}
.material-symbols-outlined{
    font-size: 2rem;
}
.eventTitle{
    margin: 0%;
    padding: 0%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    margin-bottom: 5px;
}
.eventTitleHovered{
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image: url("grad.png");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0);
}
.eventData{
    font-family: 'Titillium Web', sans-serif;
    color: rgb(0, 0, 0);
}
.titleLine{
    height:40%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    font-weight: 800;
}


@media screen and (min-width:750px){
    .includedWindows{
        display: none;
    }
    .includedPhone{
        display: none;
    }
    .eventContainer{
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media screen and (min-width:1180px){
    .included{
        padding-left: 5%;
        padding-right: 5%;
    }
    .includedWindows{
        display: block;
        padding-left: 30px;
        padding-right: 30px;
    }
    .includedPhone{
        display: none;
    }
    .includedTablet{
        display: none;
    }

}

@media screen and (min-width:600px) and (max-width:750px) {
    .photoBoothText{
        font-size: 1.1rem !important;
    }
    .photoBoothsMobile{
        display: block;
    }
    .photoBoothsTablet , .includedTablet, .photoBoothsWindow , .includedWindows{
        display: none;
    }
    .splide__pagination--ltr
    {
        position:absolute;
        top:260px;
        /* bottom: 100px; */
        /* margin-top: 20px; */
    }
   
}
@media screen and (max-width:400px){
    /* .splide__arrow--prev{
        margin-left: -30px;
    }
    .splide__arrow--next{
        margin-right: -30px;
    } */
    .included{
        padding: 0%;
    }
    .eventContainer{
        margin-right: 5%;
        margin-left: 5%;
    }
}
@media screen and (max-width:600px){
    .heading360{
        font-size: 1.8rem;
    }
    .photoBoothsTablet,.photoBoothsWindow , .includedTablet ,.includedWindows{
        display: none;
    }
    .splide__pagination--ltr{
        margin-top: 40px;
    }
    /* .splide__arrow--prev{
        margin-left: -10px;
    } */
    .eventTitle{
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            background-image: url("grad.png");
            background-size: 110% auto;
            background-position: center;
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
          }
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        font-weight: 700;
        transition: 0.5s;
        background-color: rgba(0, 0, 0, 0);
    }
    .eventTitleHovered{
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            background-image: url("grad.png");
            background-size: 110% auto;
            background-position: center;
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
          }
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        transition: 0.5s;
        background-color: rgba(0, 0, 0, 0);
    }
    
}
@media screen and (max-width:1135px) and (min-width:750px){
    .photoBoothsMobile{
        display:none;
    }
    .photoBoothsWindow{
        display: none;
    }
    .photoBoothsTablet{
        display: block;
    }
    .eventContainer{
        height: 350px;
        width:280px
    }
    .splide__pagination{
        position: absolute;
        bottom:20px
    }
    .eventInfo{
        height: 200px;
    }
}
@media screen and (min-width:1135px) {
    .photoBoothsMobile{
        display: none;
    }
    .photoBoothsTablet{
        display: none;
    }
    .photoBoothsWindow{
        display: block;
    }
    .carrouselWindow{
         padding-left: 100px;
        padding-right: 100px;
    }
    .splide__arrows--ltr{
        margin-left: 100px;
        margin-right: 100px;
    }

    
}
@media screen and (min-width:1600px){
    .includedWindows{
        padding-left: 250px;
        padding-right: 250px;
    }
}

@media screen and (max-width:600px){
    .enquiryForm{
        width:90%;
        display: flex;
        flex-direction: column;
        background: black;
    }
    .logoHolder{
        display: none;
    }
}